<template>
  <div class="gavc-field gavc-field--icon">
    <input
      autocomplete="on"
      class="gavc-input"
      @input="$emit('input')"
      :id="id"
      :maxlength="maxLength"
      v-model="value"
      :disabled="disabled"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
    >
    <i
      v-if="value"
      @click="showPassword = !showPassword"
      class="gavc-icon pointer-icon"
      :class="{'gavc-icon-eye': !showPassword, 'gavc-icon-eye-closed': showPassword }"
    />
  </div>
</template>

<script>
export default {
  name: 'passwordInput',
  props: {
    id: {
      type: String,
      default: "input"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: "50"
    },
    defaultValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      value: '',
      showPassword: false
    }
  },
  mounted () {
    this.value = this.defaultValue
  },
  watch: {
    defaultValue(newValue) {
      this.value = newValue
    },
    value(newValue) {
      this.$emit('value-changed', newValue)
    },
  }
}
</script>
